
<bc-header [menuInteraction]="true" headerType="bc-header-wrapper-black">
    <bc-header-left logoName="grupo-bancolombia-horizontal" channelName="Modulo Parametrizador Tesoreria" navigate></bc-header-left>
    <bc-header-list>
        <bc-header-item icon="lock" text="Autenticación Bancolombia" (click)="login()" ></bc-header-item>
        <bc-header-item id="home" [text]="'Inicio'" itemType="any" (click)="navigateToUrl('/home')"> </bc-header-item>
        <bc-header-item *ngIf="logged" [text]="name" itemType="user"> </bc-header-item>
        <bc-header-item
          *ngIf="logged"
          icon="quit"
          text="Cerrar sesión"
          (click)="logout()"
          route="#"
        ></bc-header-item>
    </bc-header-list>
</bc-header>

<bc-menu class="menu-app-showcase" [logoutFunction]="logout" [state]="'close'" >
    <bc-menu-drawer>
      <bc-menu-item path='/' iconName='house' identifierContent='id1' text='Inicio' ></bc-menu-item>
      <bc-menu-item *ngFor="let section of menu"  [iconName]="section.icon" identifierContent="{{section.name}}" text="{{section.name}}" ></bc-menu-item>
    </bc-menu-drawer>
    <bc-menu-main>
      <bc-menu-body *ngFor="let section of menu" identifier="{{section.name}}">
        <bc-menu-column>
          <bc-menu-section *ngIf="menu.length>0" title='Vistas de {{section.name}}'>
            <bc-menu-option *ngFor="let item of section.children" iconname="directions" text="{{item.name}}" href='{{item.path}}'></bc-menu-option>
  
            <!-- <bc-menu-option text='Clientes bloqueados' href='clientes/clientesBloqueados'></bc-menu-option>
            <bc-menu-option text='Replicar cliente Murex' href='clientes/crearClienteMx'></bc-menu-option> -->
          </bc-menu-section>
        </bc-menu-column>
      </bc-menu-body>
    </bc-menu-main>
    <div class="bc-content-body bc-h-100">
        <router-outlet></router-outlet>
    </div>
  </bc-menu>
