import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment'
import { ISession } from './core/models/session';
import { AuthService } from './core/services/auth.service';
import { RolService } from './core/services/rol.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'MptSvn';

  environment = environment.currentEnv;
  artifact = environment.releaseInfo.artifact;

  infoSession: ISession = {
    logged: false,
    profile: {
      name: '',
      username: '',
      roles: [],
    },
  };

  constructor(
    private authService: AuthService,
    private rolService: RolService,
  ) {}

  ngOnInit() {
    this.authService.initialize().subscribe(this.infoSessionObserver);
    this.authService.detectLoginSuccess().subscribe(this.infoSessionObserver);
  }

  // Mostrar/Ocultar items segun gestion de roles

  checkoutAccount() {
    this.authService.checkoutAccount().subscribe(this.infoSessionObserver);
  }

  logIn() {
    this.authService.logIn().subscribe(this.infoSessionObserver);
  }

  logOut() {
    this.authService.logOut().subscribe(this.infoSessionObserver);
  }

  infoSessionObserver = {
    next: (infoSession: ISession) => {
      this.infoSession = infoSession;
    },
    complete: () => {},
  };
}
