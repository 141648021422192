export const CLIENTES_ROUTE = 'clientes/';
export const PARAMETRIZAR_CLIENTE_ROUTE = 'parametrizarClientes';

export const OPERACIONES_ROUTE = 'operaciones/';
export const LISTA_OPERACIONES_ROUTE = 'lista';
export const SEGMENTOS_ROUTE = 'segmentos/';
export const SISTEMA_ROUTE = 'sistema/';

export const CREAR_CLIENTE_MX_ROUTE = 'crearClienteMx';
export const PARAMETRIZAR_CLIENTES = 'parametrizarClientes';
export const CLIENTES_BLOQUEADOS_ROUTE = 'clientesBloqueados';

export const LISTA_PARAMETROS_SEGMENTOS = 'lista-parametros-segmentos';

export const LISTA_OPERACIONES = 'lista-operaciones';

