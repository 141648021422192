import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperacionesRoutingModule } from './operaciones-routing.module';
import { VistaOperacionesComponent } from './vista-operaciones/vista-operaciones.component';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';

@NgModule({
  declarations: [
    VistaOperacionesComponent
  ],
  imports: [
    CommonModule,
    OperacionesRoutingModule,
    BcTableModule
  ]
})
export class OperacionesModule { }
