import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VistaOperacionesComponent } from './vista-operaciones/vista-operaciones.component';
import { RoleGuard } from '@app/core/guards/roles/role.guard';

const routes: Routes = [ 
  {
    path: 'lista',
    component: VistaOperacionesComponent,
    canActivate: [RoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperacionesRoutingModule { }
