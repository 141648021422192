<!-- https://grupobancolombia.visualstudio.com/Vicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa/_search?action=contents&text=recordOptions&type=code&lp=code-Project&filters=ProjectFilters%7BVicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa%7DRepositoryFilters%7BNU0338003_Microfrontends_PRAC_MR%7D&pageSize=25&result=DefaultCollection/Vicepresidencia%20Servicios%20de%20Tecnolog%C3%ADa/NU0338003_Microfrontends_PRAC_MR/GBtrunk//encuesta-perfil-riesgo/src/app/analysis/recommendation-history/recommendation-history.component.html -->
<div style="margin: 10px;">
    <bc-table-container
          id="prac-rh-history"
          componentId="prac-rh-history"
          class="bc-w-100"
          [dataTable]="1"
        >
          <bc-table-header title="Lista de operaciones">
          </bc-table-header>
          <bc-table-content>
            <table
              bc-table
              [sort]="true"
              [pairPaginators]="true"
              [dropdownHtml]="true"
              [scroll]="false"
            >
              <caption></caption>
              <thead>
                <tr>
                  <th scope="row" bc-cell>ID Op.</th>
                  <th scope="row" bc-cell>Tipo id</th>
                  <th scope="row" bc-cell>Número id</th>
                  <th scope="row" bc-cell>Cliente</th>
                  <th scope="row" bc-cell>Monto USD</th>
                  <th scope="row" bc-cell>Tasa Cop_Usd</th>
                  <th scope="row" bc-cell>C_V</th>
                  <th scope="row" bc-cell>Unidad organizativa</th>
                  <th scope="row" bc-cell>TD</th>
                  <th scope="row" bc-cell>Moneda</th>
                  <th scope="row" bc-cell>Monto Forex</th>
                  <th scope="row" bc-cell>Tasa Forex</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let operation of operationsResume">
                  <td bc-cell>{{ operation.IDOpe }}</td>
                  <td bc-cell>{{ operation.TipoID}}</td>
                  <td bc-cell>{{ operation.Nit }}</td>
                  <td bc-cell>{{operation.Cliente}}</td>
                  <td bc-cell>${{ operation.MontoUSD }}</td>
                  <td bc-cell>${{ operation.TasaCOP_USD}}</td>
                  <td bc-cell>{{ operation.C_V }}</td>
                  <td bc-cell>{{ operation.UnidadOrganizativa }}</td>
                  <td bc-cell>{{ operation.TD }}</td>
                  <td bc-cell>{{ operation.Moneda }}</td>
                  <td bc-cell>{{ operation.MontoForex }}</td>
                  <td bc-cell>{{ operation.TasaForex | percent}}</td>
                  <!-- <td bc-cell type="action">
                    <bc-table-dropdown
                      [row]="recommendation.id"
                      [options]="recordOptions"
                      (onChange)="dowloadPdf($event)"
                      [data]="{ other: 'data' }"
                    ></bc-table-dropdown>
                  </td> -->
                </tr>
                <!-- <tr *ngFor="let recommendation of currentPage">
                  <td bc-cell>{{ recommendation.id }}</td>
                  <td bc-cell>
                    {{ recommendation.tipoDocumento | firstCapital }}
                  </td>
                  <td bc-cell>{{ recommendation.numeroDocumento }}</td>
                  <td bc-cell>
                    {{
                      recommendation.tipoRecomendacion == "GRL"
                        ? "General"
                        : "Particular"
                    }}
                  </td>
                  <td bc-cell>
                    {{ recommendation.fecha | date : "dd/MM/yyyy" }}
                  </td>
                  <td bc-cell>
                    {{ recommendation.hora | time }}
                  </td>
                  <td bc-cell>{{ recommendation.productoRecomendado }}</td>
                  <td bc-cell>{{ recommendation.obsGeneral }}</td>
                  <td bc-cell>{{ recommendation.usuarioRed }}</td>
                  <td bc-cell>{{ recommendation.nombreAsesor }}</td>
                  <td bc-cell>{{ recommendation.medioVerificable }}</td>
                  <td bc-cell>{{ recommendation.extencionComercial }}</td>
                  <td bc-cell type="action">
                    <bc-table-dropdown
                      [row]="recommendation.id"
                      [options]="recordOptions"
                      (onChange)="dowloadPdf($event)"
                      [data]="{ other: 'data' }"
                    ></bc-table-dropdown>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </bc-table-content>
          <bc-table-footer>
            <!-- <bc-paginator
              type="full"
              [totalRegisters]="totalRecords.toString()"
              [perPage]="recordsPerPage.toString()"
              (onChangePage)="onChangePageTable($event)"
              perMultiple="5"
            ></bc-paginator> -->
          </bc-table-footer>
        </bc-table-container>
</div>