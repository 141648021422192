<br /><br /><br />
<div style="margin: 0 30px;">
  <h3>Detalle del cliente</h3>
  <br>
  <div>
    <div >
        <!-- <div bc-card >
            <h5>Información básica</h5>
            <table>
                <tbody>
                    <tr>
                        <td>Rol</td>
                        <td>{{this.customerBasicInfo?.role}}</td>
                    </tr>
                    
                    <tr>
                        <td>Nombre completo</td>
                        <td>{{this.customerBasicInfo?.fullName}}</td>
                    </tr>
    
                    <tr>
                        <td>Última actualización</td>
                        <td>{{this.customerBasicInfo?.dateLastUpdate | date}}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <div>
            <bc-accordions-group>
                <bc-accordion>
                <bc-accordion-header
                    title="Información básica"
                ></bc-accordion-header>
                <bc-accordion-content>  
                    <div class="bc-container">
                        <br>
                        <div class="bc-row">
                          <div class="bc-col-4">Nombre completo: {{this.customerBasicInfo?.fullName}}</div>
                          <div class="bc-col-4">Rol: {{this.customerBasicInfo?.role}}</div>
                          <div class="bc-col-4">Última actualización: {{this.customerBasicInfo?.dateLastUpdate | date}}</div>
                        </div>
                        <br>
                      </div>
                      
                </bc-accordion-content>
                </bc-accordion>
            </bc-accordions-group>
        </div>
    
        <div bc-card >
            <h5>Información detallada</h5>
            <table>
                <tbody>
                    <tr>
                        <td>codeCIIU</td>
                        <td>{{this.customerDetailedInfo?.codeCIIU}}</td>
                    </tr>
                    
                    <tr>
                        <td>CodeSubCIIU</td>
                        <td>{{this.customerDetailedInfo?.codeSubCIIU}}</td>
                    </tr>
    
                    <tr>
                        <td>CountryExpedition</td>
                        <td>{{this.customerDetailedInfo?.countryExpedition}}</td>
                    </tr>
    
                    <tr>
                        <td>sectorCode</td>
                        <td>{{this.customerDetailedInfo?.sectorCode}}</td>
                    </tr>
    
                    <tr>
                        <td>shortNameClient</td>
                        <td>{{this.customerDetailedInfo?.shortNameClient}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div bc-card>
        <div >
            <h5>Información fiscal</h5>
            <table>
                <tbody>
                    <tr>
                        <td>Autorretenedor</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].selfRetainerOtherIncome}}</td>
                    </tr>
                    
                    <tr>
                        <td>Imc</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].exchangeMarketIntermediary}}</td>
                    </tr>
    
                    <tr>
                        <td>Tipo contribuyente</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].taxpayerType}}</td>
                    </tr>
    
                    <tr>
                        <td>Vigilado superfinanciera</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].monitoredBySuperfinanciera}}</td>
                    </tr>
    
                    <tr>
                        <td>Vigilado superintendencia</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].monitoredBySuperintendence}}</td>
                    </tr>

                    <tr>
                        <td>Clase contribuyente</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].taxpayerClass}}</td>
                    </tr>

                    <tr>
                        <td>Codigo responsable de iva</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].VATLiable}}</td>
                    </tr>

                    <tr>
                        <td>Codigo tipo autorretenedor</td>
                        <td>{{this.taxInformation?.customer!.taxDetail[0].withholdingAgent}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div bc-card></div>

    <div bc-card></div>

    <div bc-card></div>

    <div bc-card></div>
  </div>
</div>

