<bc-circle-loading
  [text]="circleLoadingText"
  [accessibilityLabel]="circleLoadingAriaLabel"
  [speedLoader]="loaderSpeed"
></bc-circle-loading>

<div class="bc-container">
  <!-- [itemsSelect]="setDataSelect" -->
  <bc-search class="bc-direction-row" 
      [itemsSelect]="setDataSelect">
    <div
      class="bc-search-content bc-border-radius-2-full"
      [formGroup]="searchClientForm"
    >
      <bc-search-left>
        <bc-form-field>
          <!-- (valueOutput)="onChange($event)" -->
          <bc-input-select 
                formControlName="documentType"  
                [componentId]="'DT'"
                icon="id"
                [values]="documentTypes"
                type="multiple"
                label="Tipo de Documento"
                formatType="simple"
                required="true"
                span="Campo obligatorio"
                status="enabled"
              ></bc-input-select>
        </bc-form-field>
      </bc-search-left>
      <bc-search-left>
        <bc-form-field>
          <em>id-number</em>
          <input
            bc-input
            id="searchDocumentNumber"
            autocomplete="off"
            type="number"
            name="numeroDocumento"
            formControlName="documentNumber"
            required
            class="bc-input"
            aria-label="Número Documento"
            [inputValidation]="true"
          />
          <label for="02">Número Documento</label>
          <span
            *ngIf="
              searchClientForm.controls['documentNumber'].touched &&
              searchClientForm.controls['documentNumber'].invalid
            "
            >Digite un numero de documento válido</span
          >
        </bc-form-field>
        <!-- <div
            id="tooltipicon"
            *ngIf="
              searchClientForm.controls['documentType'].value"
          >
            <em
              class="bc-icon"
              bc-tooltip
              bcTooltipText="Recuerde ingresar el número del nit / fideicomiso sin el dígito de verificación"
              >info-circle</em
            >
          </div> -->
      </bc-search-left>
      <bc-search-button
        (click)="search()"
        iconSearch="search"
      ></bc-search-button>
    </div>
  </bc-search>
</div>
