import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicCustomerInformation, DetailedCustomerInformation, MockResponse, RetrieveTaxInformation } from '../models/cliente-mdm';
import { DocumentType } from '../models/mctDataTypes';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientMCTApiService {
  private apiUrl = environment.mptBaseUrl;
  readonly headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  .set('Message-id', 'fab0cb4c-544f-4cf3-9cd4-e04cd4ea58ab')
  /* .set('Message-id', '123456') */;

  constructor(private http: HttpClient) {}

  getRetrieveBasicInformation(): Observable<MockResponse<BasicCustomerInformation>> {
    return this.http.get<MockResponse<BasicCustomerInformation>>(`https://run.mocky.io/v3/8e388139-48a5-47a1-bffe-5908d83254dd`);
  }
  
  getRetrieveDetailedInformation(): Observable<MockResponse<DetailedCustomerInformation>> {
    return this.http.get<MockResponse<DetailedCustomerInformation>>(`https://run.mocky.io/v3/01c4ae02-d355-43ee-a8b3-5a8358a9ca42`);
  }

  getRetrieveTaxInformation(): Observable<MockResponse<RetrieveTaxInformation>> {
    return this.http.get<MockResponse<RetrieveTaxInformation>>(`https://run.mocky.io/v3/b35f618d-efc1-4357-96e3-76de00b07e6c`);
  }

  getDocTypeList(): Observable<DocumentType[]>{
    //return this.http.get<MockResponse<MasterCatalogueApi[]>>(`${this.apiUrl}/reference-data/market-data/reference-data-management/reference-data-management/query-master-catalogue?group=REPLACE_THIS_VALUE&code=REPLACE_THIS_VALUE`);
    
    //TIPDOC_003
    return this.http.post<DocumentType[]>(`${this.apiUrl}mct/clientes-tesoreria/consultarTiposDocumento`,{}, { headers: this.headers });
  }
}
