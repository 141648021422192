import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MockResponse } from '../models/cliente-mdm';
import { Observable } from 'rxjs';
import { OperationResume } from '../models/operaciones-mx';

@Injectable({
  providedIn: 'root'
})
export class OpsMurexApiService {
  apiUrl = 'https://run.mocky.io/v3';
  
  constructor(private http: HttpClient) {}
  getAllOperations(): Observable<OperationResume[]> {
    return this.http.get<OperationResume[]>(`${this.apiUrl}/418d97fc-92e3-456f-98da-2c1af6c200bd`);
  }
}
