import { Inject, Injectable, OnInit } from '@angular/core';

import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { IProfile } from '../models/profile';
import { ISession } from '../models/session';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  method = 'redirect';
  loggedIn: boolean = false;
  profile = {} as IProfile;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfiguration: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe();
  }

  initialize(): Observable<ISession> {
    return this.broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(() => this.checkoutAccount()),
      takeUntil(this._destroying$)
    );
  }

  detectLoginSuccess(): Observable<ISession> {
    return this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      switchMap(() => this.checkoutAccount())
    );
  }

  logIn(): Observable<ISession> {
    return (
      this.method !== 'redirect' ? this.logInPopup() : this.logInRedirect()
    ).pipe(switchMap(() => this.checkoutAccount()));
  }

  checkoutRoles(): string {
    let role: any;
    const user = this.msalService.instance.getAllAccounts().shift();
    if(user?.idTokenClaims?.roles){
      const rolesAvailable = user.idTokenClaims.roles.some( roleAvailable => roleAvailable.includes('MPT_SVN_PARAM'));
      
      // Si existe el rol 'MPT_SVN_PARAM' dentro de la lista, seleccionarlo
      if(rolesAvailable) {
        role = 'MPT_SVN_PARAM'
      }
      // Si no existe 'MPT_SVN_JEFE' dentro de la lista, selecciona el primer rol disponible
      else {
        role = user.idTokenClaims.roles.shift();
      }
    }
    return role;
  }

  checkoutAccount(): Observable<ISession> {
    const logged = this.msalService.instance.getAllAccounts().length > 0;
    let profile = {
      name: '',
      username: '',
      roles: [],
    };

    if (logged) {
      const account: any = this.msalService.instance.getAllAccounts().shift();
      if (account.idTokenClaims?.roles.length == 0) {
        this.router.navigate(['/unauth']);
      }
      profile = {
        name: account.name,
        username: account.username,
        roles: account.idTokenClaims?.roles,
      };
    } else {
      this.logIn();
    }

    return of({
      logged: logged,
      profile: profile,
    } as ISession);
  }

  private logInPopup(): Observable<any> {
    if (this.msalGuardConfiguration.authRequest) {
      return this.msalService.loginPopup({
        ...this.msalGuardConfiguration.authRequest,
      } as PopupRequest);
    } else {
      return this.msalService.loginPopup();
    }
  }

  private logInRedirect(): Observable<any> {
    if (this.msalGuardConfiguration.authRequest) {
      return this.msalService.loginRedirect({
        ...this.msalGuardConfiguration.authRequest,
      } as RedirectRequest);
    } else {
      return this.msalService.loginRedirect();
    }
  }

  logOut(): Observable<ISession> {
    return (
      this.method !== 'redirect' ? this.logOutPopup() : this.logOutRedirect()
    ).pipe(switchMap(() => this.checkoutAccount()));
  }

  private logOutPopup(): Observable<any> {
    return this.msalService.logoutPopup({ mainWindowRedirectUri: '/' });
  }

  private logOutRedirect(): Observable<any> {
    return this.msalService.logoutRedirect({ postLogoutRedirectUri: '/' });
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
