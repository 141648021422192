import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SegmentosRoutingModule } from './segmentos-routing.module';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { VistaParametrosSegmentosComponent } from './vista-parametros-segmentos/vista-parametros-segmentos.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import {BcLoaderModule} from '@bancolombia/design-system-web/bc-loader';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';


@NgModule({
  declarations: [
    VistaParametrosSegmentosComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SegmentosRoutingModule,
    BcTableModule,
    BcModalModule,
    BcInputSelectModule,
    BcInputModule,
    BcTooltipModule,
    BcLoaderModule,
    BcIconButtonModule,
    BcButtonModule,
    
    BcIconModule.forRoot(
      { 
        path: 'https://library-sdb.apps.bancolombia.com/bds/7.7.10' 
      }
    ),
  ],
})
export class SegmentosModule { }
