import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { ClientesBloqueadosComponent } from './clientes-bloqueados/clientes-bloqueados.component';
import { ClientesRoutingModule } from './clientes-routing.module';
import { VistaCrearClienteMxComponent } from './vista-crear-cliente-mx/vista-crear-cliente-mx.component';
import { DetalleClienteComponent } from './detalle-cliente/detalle-cliente.component';
import { VistaParametrosClientesComponent } from './vista-parametros-clientes/vista-parametros-clientes.component';

import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcCircleLoadingModule } from '@bancolombia/design-system-web/bc-circle-loading';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BuscarClienteComponent } from './buscar-cliente/buscar-cliente.component';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcPaginatorV2Module } from '@bancolombia/design-system-web/bc-paginator-v2';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { BcFloatMenuModule } from '@bancolombia/design-system-web/bc-float-menu';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';

@NgModule({
  declarations: [
    BuscarClienteComponent,
    DetalleClienteComponent,
    VistaCrearClienteMxComponent,
    VistaParametrosClientesComponent,
    ClientesBloqueadosComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClientesRoutingModule,

    BcInputModule,
    BcCircleLoadingModule,
    BcAlertModule,
    BcModalModule,
    BcCardModule,
    BcTableModule,
    BcIconButtonModule,
    BcAccordionModule,
    BcPageHeaderModule,
    BcSearchModule,
    BcPaginatorModule,
    BcPaginatorV2Module,
    BcButtonModule,
    BcLoaderModule,
    BcTooltipModule,
    BcInputModule,
    BcFloatMenuModule,
    BcHeaderModule,
    BcInputSelectModule,
    BcMenuModule,
    
    BcIconModule.forRoot(
      { 
        path: 'https://library-sdb.apps.bancolombia.com/bds/7.7.10' 
      }
    ),
  ],
})
export class ClientesModule {}
