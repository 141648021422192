import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParameterizedSegmentSummary } from '../models/segment-mpt';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentMptApiService {
  private apiUrl = environment.mptBaseUrl;
  readonly headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  .set('Message-id', '123456');
  readonly options = { headers: this.headers };

  constructor(private http: HttpClient) {}

  getSubSegments(): Observable<ParameterizedSegmentSummary[]> {
    return this.http.get<ParameterizedSegmentSummary[]>(`${this.apiUrl}mpt/clientes-mpt/adminSegmento/consultarTodasRestriccionesXSegmento`, this.options);
  }

  saveSubSegmentParameters(subsegment: ParameterizedSegmentSummary): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}mpt/clientes-mpt/adminSegmento/guardarRestriccionesXSegmento`, subsegment, this.options);
  }

  getSegmentList(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}mct/clientes-tesoreria/consultarSegmentos`,{},  this.options);
  }
}
