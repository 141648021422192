import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, UrlSegment, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { rolesConfig } from '../config/roles.config';
import { RolService } from '@app/core/services/rol.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard {

  constructor(private rolService: RolService,
    private router: Router) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{

    if (!this.getRolesFromPath(route.routeConfig?.path)) {
      // Si el usuario no tiene el rol requerido, redirigir a la página de acceso denegado que cumpla con su condicion
      // Retorna (true o false) si el rol actual está dentro de los roles del path a acceder
      this.rolService.getUserRoles() === 'PARAMETRIZACION' ? this.router.navigate(['/clientes/parametrizarClientes']) : this.router.navigate(['/']);
      return false;
    }
    // Dejamos acceder a la ruta
    return true;
  }
  
  // Obtenemos los roles permitidos segun el path al que se quiere acceder
  getRolesFromPath(path: any): any {
    for (const routeConfig of rolesConfig.routes) {
      for (const child of routeConfig.children) {
        if (child.path === path) {
          return child.roles.some(role => path);
        }
      }
    }
  }

}