import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BasicParameterizedClientSummary, ParameterizedClientSummary } from '../../../../src/app/core/models/client-mpt';
import { IDeleteObject, ISelectValues } from '../../../../src/app/core/models/informacion-parametrica';
import { ClientMptApiService } from '../../../app/core/services/client-mpt-api.service';
import { RolService } from '@app/core/services/rol.service';
import { Router } from '@angular/router';
import { AlertService } from '@app/core/services/alert.service';
import { AlertType } from '@app/core/models/alert-params';
import { MdmApiService } from '@app/core/services/mdm-api.service';

@Component({
  selector: 'app-vista-parametros-clientes',
  templateUrl: './vista-parametros-clientes.component.html',
  styleUrls: ['./vista-parametros-clientes.component.scss']
})
export class VistaParametrosClientesComponent implements OnInit{

  modalTittle: string = "Parametrizacion cliente";
  @ViewChild('customerParam', { static: false })
  customerParam!: BcModalComponent;
  currentClient!: ParameterizedClientSummary;
  editPrivileges: boolean = false;
  clientFound: boolean = false;
  createClientMode: boolean = false;
  mptClients: string = "mptClients";
  auxClientsList!: ParameterizedClientSummary[];
  clientList!: ParameterizedClientSummary[];
  backupClient: ParameterizedClientSummary | undefined;
  loading = true;

  yesNoPaymentOrderText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene ordenes de pago disponibles
    \n No: Con esta opción el cliente podrá pactar una tasa sin tener ordenes de pago disponibles.`;
  yesNoAccountBalanceText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene saldo disponible en cuenta.
  \n No: Con esta opción el cliente podrá pactar una tasa sin tener saldo disponible en cuenta.`;
  divisas: ISelectValues[]= [
    { label: 'USD', value: 1 },
    { label: 'COP', value: 2 }
  ];

  siNo: ISelectValues[]= [
    { label: 'Si', value: true },
    { label: 'No', value: false }
  ];

  habDesh: ISelectValues[]= [
    { label: 'Habilitado', value: true },
    { label: 'Deshabilitado', value: false }
  ];


  currencyForm: FormGroup;

  constructor(
    private clientMptService: ClientMptApiService,
    private fb: FormBuilder, 
    private roleService: RolService,
    private router:Router,
    private alertService: AlertService,
    private mdmApiService: MdmApiService) {
    this.currencyForm = this.fb.group({
      id: [null ],
      idCliente: [null,[Validators.required] ],
      documentType: ["CC"],
      documentNumber: ['', [Validators.min(0)]],
      currency: ["USD", [Validators.required, Validators.min(0)]],
      enterpriseName: ["USD", [Validators.required, Validators.min(0)]],
      minimumSaleAmount: ['', [Validators.required, Validators.min(1), Validators.max(1000000)]],//NOSONAR
      maximumSaleAmount: ['', [Validators.required, Validators.min(1), Validators.max(1000000)]],//NOSONAR
      minimumPurchaseAmount: ['', [Validators.required, Validators.min(1), Validators.max(1000000)]],//NOSONAR
      maximumPurchaseAmount: ['', [Validators.required, Validators.min(1), Validators.max(1000000)]],//NOSONAR
      validatePaymentOrder: ["Si", [Validators.required]],
      validateAccountBalance: ["Si", [Validators.required]],
      status: ["Habilitado", [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.loadClientList();
    this.editPrivileges = this.roleService.enablePrivileges((this.router.url.split('/')[2]));
  }

  loadClientList(){
    this.loading = true;
    this.clientMptService.getRetrieveBasicInformation().subscribe({
      next: (data: BasicParameterizedClientSummary[]) =>{
        this.clientList = data.map(this.mapToParameterizedClientSummary);
        this.auxClientsList = this.clientList;
      },
      error: (error: any) => {
        console.log(error);
        this.alertService.showAlert(AlertType.Error, error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onModalPressButton(event: any) {
    switch (event) {
      //cancelar
      case 0:
        
        break;
      //confirmar
      case 1:
        if (this.createClientMode) {
          this.createClientMode = false;
        }else {
          this.onSubmit()
        }
      break;
    
      default:
        break;
    }
    this.customerParam.shutDown();
  }
  openModal(client: ParameterizedClientSummary) {
    this.currentClient = client;
    this.currencyForm.reset();
    
    this.currencyForm.patchValue({
      id: client.id,
      idCliente: client.cliente.id,
      documentType: client.cliente.type,
      documentNumber: client.cliente.number,
      currency: this.divisas.find(x => x.label == "USD")?.value,
      enterpriseName: client.cliente.name,
      minimumSaleAmount: client.montoMinEnvio,
      minimumPurchaseAmount:  client.montoMinRecepcion,
      maximumSaleAmount: client.montoMaxEnvio,
      maximumPurchaseAmount: client.montoMaxRecepcion, 
      validatePaymentOrder: client.ordenPago,
      validateAccountBalance:  client.saldoCuenta,
      status: client.status || false,
    })
    
    this.customerParam.openModal();
  }
  
  updateClientFound(client: ParameterizedClientSummary | any){
    this.backupClient = client;
    if (client.id && client.idCliente) {
      this.clientList = [];
      this.clientList.push(client);
      this.clientFound = true;
    } else {
      this.clientFound = false;
      this.createClientMode = true;
      /* if (client.code !="VDB003")  */
      this.clientList = [];
      this.alertService.showAlert(AlertType.Error, 'No se encontró el cliente',2000);
    }
  }

  deleteClientRestrictions(client: ParameterizedClientSummary){
    this.loading = true;
    if (!client.id) {
      this.loading = false;
      return;
    }
    const clientToDelete: IDeleteObject = {	
      id: client.id ,
    }
    this.clientMptService.postDeleteClientRestrictions(clientToDelete).subscribe({
      next: (data: any) => {
        this.loadClientList();
      },
      error: (error: any) => {
        this.alertService.showAlert(AlertType.Error, error);
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        this.alertService.showAlert(AlertType.Success, 'Se ha eliminado el cliente correctamente');
      }
    });
  }

  onSubmit() {
    this.loading = true;
    const formValues = this.currencyForm.value;
    
    if (this.currencyForm.valid) {
      const clientRestrictionsToSave: ParameterizedClientSummary = {
        id: formValues.id,
        cliente:{
          id:formValues.idCliente,
          type:formValues.documentType,
          number:formValues.documentNumber,
          name:formValues.enterpriseName,
        },
        moneda: formValues.currency || 'USD',
        montoMinEnvio: formValues.minimumSaleAmount,
        montoMinRecepcion: formValues.minimumPurchaseAmount,
        montoMaxEnvio: formValues.maximumSaleAmount,
        montoMaxRecepcion: formValues.maximumPurchaseAmount,
        ordenPago: formValues.validatePaymentOrder,
        saldoCuenta: formValues.validateAccountBalance,
        status: formValues.status === "Habilitado"? true: false
      };

      this.clientMptService.postSaveClientRestrictions(clientRestrictionsToSave).subscribe({
        next: (data: any) => {
          this.loadClientList();
        },
        error: (error: any) => {
          this.alertService.showAlert(AlertType.Error, error);
          console.log(error);
        },
        complete: () => {
          if (formValues.id) 
            this.alertService.showAlert(AlertType.Success, 'Se ha actualizado la restricción correctamente');
          else 
            this.alertService.showAlert(AlertType.Success, 'Se ha creado la restricción correctamente');

          this.loading = false;
        }
      });
    }
  }

  clearClientSearch(){
    this.clientList = this.auxClientsList;
    this.clientFound = false;
    this.createClientMode = false;
  }

  addClientRestrictions(){
    this.currencyForm.get('documentType')?.setValue(this.backupClient?.cliente.type);
    this.currencyForm.get('documentNumber')?.setValue(this.backupClient?.cliente.number);
    this.loading = true;
    this.createClientMode = true;
    this.mdmApiService.postAskMdmClient(this.currencyForm.value.documentType, this.currencyForm.value.documentNumber).subscribe({
      next: (mdmClient: any) =>{       
        const client:ParameterizedClientSummary = {
          id: "0",
          cliente: {
            id: 0,
            type: mdmClient.data.customer.identification.type,
            number: mdmClient.data.customer.identification.number,
            name: mdmClient.data.customer.generalInformation.fullName
          },
          moneda: 1,
          montoMinEnvio: "0",
          montoMinRecepcion: "0",
          montoMaxEnvio: "0",
          montoMaxRecepcion: "0",
          ordenPago: false,
          saldoCuenta: false,
          status: { label: 'Habilitado', value: 1 }
        };
        this.openModal(client);
      },
      error: (error: any) => {
        this.alertService.showAlert(AlertType.Error, error);
        console.log(error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  showOutput(event: any) {
  }
  mapToBasicParameterizedClientSummary(
    paramClient: ParameterizedClientSummary
  ): BasicParameterizedClientSummary {
    return {
      id: paramClient.id,
      idCliente: paramClient.cliente.id, // Assuming `ICliente` has an `id` property
      type: paramClient.cliente.type, // Assuming `ICliente` has a `type` property
      number: paramClient.cliente.number, // Assuming `ICliente` has a `number` property
      subsegment: paramClient.subsegment,
      moneda: paramClient.moneda as ISelectValues | string | number, // Type assertion
      name: paramClient.cliente.name, // Assuming `ICliente` has a `name` property
      montoMinEnvio: paramClient.montoMinEnvio,
      montoMinRecepcion: paramClient.montoMinRecepcion,
      montoMaxEnvio: paramClient.montoMaxEnvio,
      montoMaxRecepcion: paramClient.montoMaxRecepcion,
      ordenPago: paramClient.ordenPago,
      saldoCuenta: paramClient.saldoCuenta,
      status: paramClient.status
    };
  }
  mapToParameterizedClientSummary(
    basicClient: BasicParameterizedClientSummary
  ): ParameterizedClientSummary {
    return {
      id: basicClient.id,
      cliente: {
        id: basicClient.idCliente,
        type: basicClient.type,
        number: basicClient.number,
        name: basicClient.name
      },
      subsegment: basicClient.subsegment,
      moneda: basicClient.moneda as number | boolean | undefined,
      montoMinEnvio: basicClient.montoMinEnvio,
      montoMinRecepcion: basicClient.montoMinRecepcion,
      montoMaxEnvio: basicClient.montoMaxEnvio,
      montoMaxRecepcion: basicClient.montoMaxRecepcion,
      ordenPago: basicClient.ordenPago,
      saldoCuenta: basicClient.saldoCuenta,
      status: basicClient.status
    };
  }
}
