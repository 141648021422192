import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { ISelectValues } from '../../../../src/app/core/models/informacion-parametrica';
import { ParameterizedSegmentSummary } from '../../../../src/app/core/models/segment-mpt';
import { AlertService } from '@app/core/services/alert.service';
import { AlertType } from '@app/core/models/alert-params';
import { SegmentMptApiService } from '@app/core/services/segment-mpt-api.service';
import { forkJoin } from 'rxjs';
import { RolService } from '@app/core/services/rol.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vista-parametros-clientes',
  templateUrl: './vista-parametros-segmentos.component.html',
  styleUrls: ['./vista-parametros-segmentos.component.scss'],
})
export class VistaParametrosSegmentosComponent implements OnInit {
  action = false;
  typeIcon = 'error';
  closeModal = false;
  customWidth = 'small';
  titleAlign = '';
  buttonAlignment = 'horizontal';
  selectedValue = '';
  subTitle = '';
  loading = false;

  yesNoPaymentOrderText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene ordenes de pago disponibles
    \n No: Con esta opción el cliente podrá pactar una tasa sin tener ordenes de pago disponibles.`;
  yesNoAccountBalanceText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene saldo disponible en cuenta.
  \n No: Con esta opción el cliente podrá pactar una tasa sin tener saldo disponible en cuenta.`;
  modalTittle: string = 'Parametrizacion segmento';
  @ViewChild('customerParam', { static: false })
  customerParam!: BcModalComponent;

  subsegmentList!: ISelectValues[];
  subsegmentParams!: ParameterizedSegmentSummary[];
  subsegmentParamsAux!: ParameterizedSegmentSummary[];
  currentSegment!: ParameterizedSegmentSummary;
  subsegmentsParamsForm: FormGroup;
  searchSubsegment: FormGroup;
  docTypes: ISelectValues[] = [
    { label: 'CC', value: 1 },
    { label: 'NIT', value: 2 },
  ];

  divisas: ISelectValues[] = [
    { label: 'USD', value: 1 },
    { label: 'COP', value: 2 },
  ];

  siNo: ISelectValues[] = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ];
  editPrivileges = false;
  habDesh: ISelectValues[] = [
    { label: 'Habilitado', value: 1 },
    { label: 'Deshabilitado', value: 2 },
  ];
  constructor(
    private segmentMptService: SegmentMptApiService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private roleService: RolService,
    private router:Router,
  ) {
    this.searchSubsegment = this.fb.group({ 
      selector: [''] 
    });
    this.subsegmentsParamsForm = this.fb.group({
      id: ['', [Validators.required]],
      idSegmento: ['', [Validators.required]],
      subsegment: ['', []],
      currency: ['USD', [Validators.required, Validators.min(0)]],
      minimumSaleAmount: [
        '',
        [Validators.required, Validators.min(1), Validators.max(1000000)],
      ],
      maximumSaleAmount: [
        '',
        [Validators.required, Validators.min(1), Validators.max(1000000)],
      ],
      minimumPurchaseAmount: [
        '',
        [Validators.required, Validators.min(1), Validators.max(1000000)],
      ],
      maximumPurchaseAmount: [
        '',
        [Validators.required, Validators.min(1), Validators.max(1000000)],
      ],
      validatePaymentOrder: ['Si', [Validators.required]],
      validateAccountBalance: ['Si', [Validators.required]],
      status: ['Habilitado', []],
    });
  }

  values = [];
  selectorFilter = new UntypedFormControl();

  ngOnInit(): void {
    
    this.loading = true;
    this.editPrivileges = this.roleService.enablePrivileges((this.router.url.split('/')[2]));
    const subSegments$ = this.segmentMptService.getSubSegments();
    const segmentListCall$ = this.segmentMptService.getSegmentList(); // Replace with your actual API call

    forkJoin([subSegments$, segmentListCall$]).subscribe({
      next: ([subSegmentsData, segmentListData]) => {
        this.subsegmentList = segmentListData.map((x: any) => {
          return {
            label: x.segmento,
            value: x.idSegmento,
            other: {
              codClasificacion: x.codClasificacion,
              codSegmento: x.codSegmento,
            },
          };
        });
        this.subsegmentParams = subSegmentsData.map((x: any) => {
          return { ...x, idSegmento: x.idSegmento.trim(),
            subsegment: this.subsegmentList.find((y: any) => x.id == y.value)?.label,
           };
        });
        this.subsegmentParamsAux = this.subsegmentParams;
      },
      error: (err: any) => {
        console.log("error:", err);
        this.alertService.showAlert(AlertType.Error, "Error al cargar los datos");
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  loadSubSegments(){
    this.loading = true;
    this.segmentMptService.getSubSegments().subscribe({
      next: (data: ParameterizedSegmentSummary[]) => {
        this.subsegmentParams = data.map((x: any) => {
          return { ...x, idSegmento: x.idSegmento.trim(),
            subsegment: this.subsegmentList.find((y: any) => x.id == y.value)?.label,
           };
        });
        this.subsegmentParamsAux = this.subsegmentParams;
      },
      error: (err: any) => {
        console.log('error:', err);
        this.alertService.showAlert(
          AlertType.Error,
          'Error al cargar los segmentos.'+err
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  showSelectedValue(value: any) {
    this.subsegmentParams = this.subsegmentParamsAux.filter(
      (x) => parseInt(x.idSegmento) == value
    ) || [];
  }
  status: any;

  clearClientSearch() {
    this.searchSubsegment.reset();
    this.subsegmentParams = this.subsegmentParamsAux;
  }

  onModalPressButton(event:any) {
    switch (event) {
      //cancelar
      case 0:
        this.customerParam.shutDown(); 
        
        break;
      //confirmar
      case 1:
          this.onSubmit();
      break;
    
      default:
        break;
    } 
  }
  openModal(client: ParameterizedSegmentSummary) {
    this.currentSegment = client;
    this.subsegmentsParamsForm.reset();
    this.subsegmentsParamsForm.patchValue({
      id: client.id,
      idSegmento: client.idSegmento,
      subsegment: client.subsegment,
      currency: this.divisas.find((x) => x.label == 'USD')?.value,
      maximumPurchaseAmount: client.montoMaxRecepcion || 0,
      maximumSaleAmount: client.montoMaxEnvio || 0,
      minimumPurchaseAmount: client.montoMinRecepcion || 0,
      minimumSaleAmount: client.montoMinEnvio || 0,
      validatePaymentOrder: client.ordenPago,
      validateAccountBalance: client.saldoCuenta,
      status: client.status?.value,
    });
    this.customerParam.openModal();
  }


  onSubmit() {
    const formValues = this.subsegmentsParamsForm.value;
    if (this.subsegmentsParamsForm.valid) {
      this.loading = true;
      const subsegmentRestrictionsToSave: ParameterizedSegmentSummary = {
        id: formValues.id,
        idSegmento: formValues.idSegmento,
        moneda: this.divisas.find((x) => x.value == formValues.currency)?.label || 'USD',
        montoMaxEnvio: formValues.maximumSaleAmount,
        montoMaxRecepcion: formValues.maximumPurchaseAmount,
        montoMinEnvio: formValues.minimumSaleAmount,
        montoMinRecepcion: formValues.minimumPurchaseAmount,
        ordenPago: formValues.validatePaymentOrder,
        saldoCuenta: formValues.validateAccountBalance,
        status: formValues.status,
      };
      this.segmentMptService.saveSubSegmentParameters(subsegmentRestrictionsToSave).subscribe({
        next: (data: any) => {
          this.alertService.showAlert(AlertType.Success, 'Datos guardados correctamente');
          this.loadSubSegments();
        },
        error: (err: any) => {
          console.log('error:', err);
          this.alertService.showAlert(AlertType.Error, 'Error al guardar los datos. '+err);
        },
        complete: () => {
          this.loading = false;
          this.customerParam.shutDown(); 
        },
      });
    }
  }

}

