<div class="bc-h-75">
    <div class="bc-container bc-mt-5 bc-container-16 bc-page-header bc-bg-light" >
        <h4>Listado de Clientes Bloqueados</h4>
    </div>
    <div *ngIf="!loaded"  class="bc-h-50 bc-container bc-flex bc-justify-content-center bc-align-content-center bc-flex-wrap">
        <bc-loader sizeLoader="3xl"></bc-loader>
    </div>
    <div *ngIf="loaded">
        <div class="bc-container" >
            <div class="bc-flex bc-justify-content-lg-space-evenly bc-justify-content-xl-space-evenly">
                <div class="bc-p-2 bc-w-70">
                    <app-buscar-cliente [clientSource]="mptClients" (client)="updateClientFound($event)"></app-buscar-cliente>
                </div>
                <div class="bc-flex bc-justify-content-space-between bc-w-30">
                    <div class="bc-align-self-center" bc-flex>
                        <button *ngIf="clientFound" bc-button bc-align-self-center>
                            <span>Agregar</span>
                            <bc-icon>plus</bc-icon>
                          </button>
                    </div>
                    <div class="bc-align-self-center" bc-flex>
                        <button *ngIf="clientFound" bc-button bc-align-self-center (click)="clearClientSearch()">
                            <span>Limpiar búsqueda</span>
                            <bc-icon>refresh</bc-icon>
                        </button>
                    </div>
                </div>
                <!-- <bc-search *ngIf="" class="bc-direction-row-reverse bc-align-self-end">
                    <div class="bc-search-content bc-border-radius-2-full ">
                        <bc-search-left>
                            <bc-form-field>
                                <label>Agregar</label>
                            </bc-form-field>
                        </bc-search-left>
                        <bc-icon-button class="bc-align-self-lg-end"
                            type="ghost"
                            color="white" 
                            size="large" 
                            ariaLabel="Agregar" 
                            customIcon="plus"
                        ></bc-icon-button>
                    </div>
                </bc-search> -->
        
                
            </div>
        </div>
        
        <div class="bc-container bc-pt-4">
            <div class="bc-flex bc-justify-content-lg-flex-center bc-justify-content-xl-flex-center">
                <bc-table-container 
                    [dataTable]="blockedClientsList"
                    class="bc-col-12 bc-w-100"          
                >
                    <!-- <bc-table-header title=" ">
                        <bc-paginator-v2 
                            [type]="'numeric'" 
                            [totalItems]="100" 
                            [initialPage]="1" 
                            [itemsPerPage]="20" 
                            [showPageSize]="true" 
                            pageSizeOptions="10,20,30,40,50,60,70,80,90,100"
                            [showInfoItems]="true" 
                            [customTextInfoItems]="'movimientos'" 
                            (onChangePage)="onChangePageNumeric($event)" 
                        > 
                        </bc-paginator-v2>
                    </bc-table-header> -->
                    <bc-table-content>
                        <table 
                            caption="tabla" 
                            bc-table 
                            [selection]="true" 
                            [scroll]="true" 
                            [sort]="true" 
                            [inlineActions]="true"
                        >
                            <thead>
                            <tr>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Tipo Documento</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Numero Documento</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Empresa</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Trader</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Última Modificación</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Motivo Bloqueo</th>
                                <th style="text-align: center;" scope="row" bc-cell type="total">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let client of blockedClientsList">
                                <td bc-cell>{{ client.documentType.label }}</td>
                                <td bc-cell>{{ client.documentNumber }}</td>
                                <td bc-cell>{{ client.enterpriseName }}</td>
                                <td bc-cell>{{ client.trader }}</td>
                                <td bc-cell>{{ client.lastModificationDate }}</td>
                                <td bc-cell>{{ client.blockingReason }}</td>
                                <td bc-cell>
                                    <!-- name = edit -->
                                    <i *ngIf="canParametrize" bc-icon class="icon-op">
                                    <bc-icon bc-icon name="edit" class="icon-op"> </bc-icon>
                                    </i>
                                    <i *ngIf="canParametrize" bc-icon class="icon-op">
                                    <bc-icon bc-icon name="trash" class="icon-op"> </bc-icon>
                                    </i>
                                    <!-- <button (click)="openModal()">editar cliente</button> -->
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </bc-table-content>
                    <bc-table-footer>
                        <bc-paginator-v2 
                            [type]="'numeric'" 
                            [totalItems]="100" 
                            [initialPage]="1" 
                            [itemsPerPage]="20" 
                            [showPageSize]="true" 
                            pageSizeOptions="10,20,30,40,50,60,70,80,90,100"
                            [showInfoItems]="true" 
                            [customTextInfoItems]="'movimientos'" 
                            (onChangePage)="onChangePageNumeric($event)" 
                        > 
                        </bc-paginator-v2>
                    </bc-table-footer>
                </bc-table-container>
            </div>   
        </div>
    </div>
    
</div>

