import { Injectable } from '@angular/core';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent} from '@bancolombia/design-system-web/bc-alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private bcDialogService:BcDialogService ) { }

  showAlert(type: string, text: string, timeout?: number){
    const dialogRef = this.bcDialogService.open(BcAlertComponent, {
      id: 'MyAlertId',
      type: type,
      text: text,
      timeout: timeout || 1000,
    });
  }
}
