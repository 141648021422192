import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MdmApiService {
  apiUrl = 'https://gw-mdm-qa.apps.ambientesbc.lab/private-bancolombia/testing/v2';
  readonly headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  .set('Client-Id', 'cfc0cfd071b1b9cc60a2bd11957bc958')
  .set('Client-Secret', '18445a19f55483b23d8b2c78c7200593')
  .set('message-id', '13447068-9653-4fef-8179-5e2892be27a8');

  constructor(private http: HttpClient) {}

  postAskMdmClient(documentType:string,documentNumber:string): Observable<any> {
    const params = {
      data: {
        customer: {
          identification: {
            type: documentType,//"TIPDOC_FS001"
            number: documentNumber ? documentNumber.toString() : documentNumber //"18051805462"
          }
        }
      }
    }
    return this.http.post<any>(`${this.apiUrl}/sales-services/customer-management/customer-reference-data-management/customer-personal-data/information/retrieve-basic-information`, params, { headers: this.headers });
  }
}
