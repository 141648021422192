import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VistaParametrosSegmentosComponent } from './vista-parametros-segmentos/vista-parametros-segmentos.component';
import { RoleGuard } from '@app/core/guards/roles/role.guard';

const routes: Routes = [
  {
    path: 'lista-parametros-segmentos',
    component: VistaParametrosSegmentosComponent,
    canActivate: [RoleGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegmentosRoutingModule { }
