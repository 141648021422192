import { Component, OnInit } from '@angular/core';
import { OperationResume } from '../../../../src/app/core/models/operaciones-mx';
import { OpsMurexApiService } from '../../../../src/app/core/services/ops-murex-api.service';

@Component({
  selector: 'app-vista-operaciones',
  templateUrl: './vista-operaciones.component.html',
  styleUrls: ['./vista-operaciones.component.scss']
})
export class VistaOperacionesComponent implements OnInit{
  operationsResume!: OperationResume[];

  constructor(private opsMurexApiService: OpsMurexApiService){

  }

  ngOnInit(): void {
    this.opsMurexApiService.getAllOperations().subscribe({
      next: (data: OperationResume[]) =>{
        this.operationsResume = data;
        
      },
      error: (err) => {
        
      },
    }
    )

  }

}
