import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VistaParametrosClientesComponent } from './vista-parametros-clientes/vista-parametros-clientes.component';
import { ClientesBloqueadosComponent } from './clientes-bloqueados/clientes-bloqueados.component';
import { DetalleClienteComponent } from './detalle-cliente/detalle-cliente.component';
import { RoleGuard } from '@app/core/guards/roles/role.guard';

const routes: Routes = [
  {
    path: 'parametrizarClientes',
    component: VistaParametrosClientesComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'clientesBloqueados',
    component: ClientesBloqueadosComponent,
    canActivate: [RoleGuard]
  },
  {
    path: 'detalleCliente',
    component: DetalleClienteComponent,
    canActivate: [RoleGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientesRoutingModule { }
