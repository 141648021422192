import { Component, OnInit } from '@angular/core';
import { BasicCustomerInformation, DetailedCustomerInformation, MockResponse, RetrieveTaxInformation } from '../../../../src/app/core/models/cliente-mdm';
import { ClientMCTApiService } from '../../../app/core/services/client-mct-api.service';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit{

  customerBasicInfo :BasicCustomerInformation | undefined;
  customerDetailedInfo :DetailedCustomerInformation | undefined;
  taxInformation: RetrieveTaxInformation | undefined;

  constructor(private clientMctApiService:ClientMCTApiService){

  }

  ngOnInit(): void {
    this.clientMctApiService.getRetrieveBasicInformation().subscribe({
      next: (data: MockResponse<BasicCustomerInformation>) => {
        this.customerBasicInfo = data.data;
      },
      error: (error: any) => {
        console.log(error);
      }
    });

    this.clientMctApiService.getRetrieveDetailedInformation().subscribe({
      next: (data: MockResponse<DetailedCustomerInformation>) => {
        this.customerDetailedInfo = data.data;
      },
      error: (error: any) => {
        console.log(error);
      }
    });

    this.clientMctApiService.getRetrieveTaxInformation().subscribe({
      next: (data: MockResponse<RetrieveTaxInformation>) => {
        this.taxInformation = data.data;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

}
